import React from 'react'
import MainBanner from './MainBanner';
 
class Success extends React.Component {
    
    render(){
        return (
            <MainBanner />
        );
    }
}
 
export default Success;