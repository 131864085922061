import React from 'react'
import MainBanner from './MainBanner';
 
class CancellationRefund extends React.Component {
    render(){
        return (
            <MainBanner />
        );
    }
}
 
export default CancellationRefund;